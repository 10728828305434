import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'

import { default as Tweet } from "../tweet"
import TweetCard from "../tweetcard"
import BookDetail from "../bookdetail"
import BookDescription from "../bookdescription"
import Thumbnail from "../thumbnail"
import BuyButtonBar from '../ui/buybuttonbar'

import { hyphenatedName } from '../../services/format'

function Reco({reco}){
    const { tweet_id, bookObjects } = reco
    const recoLink = '/recos/' + hyphenatedName(bookObjects[0].title)
    const id = tweet_id + '-' + hyphenatedName(bookObjects[0].title)

    return(
        <React.Fragment>
        <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
                <Row>
                    <Col xs={5} sm={5} md={3} lg={4}>
                        <Link to={recoLink}><Thumbnail thumbnail={ bookObjects[0].thumbnail } alt={bookObjects[0].title}/></Link>
                    </Col>
                    <Col xs={7} sm={7} md={8} lg={8}>
                        <BookDetail book={ bookObjects[0] } recoLink={recoLink}/>
                        <BookDescription book={ bookObjects[0]} />
                        <BuyButtonBar book={ bookObjects[0] }/>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
                {reco.text ? <TweetCard reco = {reco}/>:
                <Tweet tweetId = { tweet_id } Id= {id}/>}
            </Col> 
        </Row> 
        </React.Fragment>
    )
}

export default Reco;