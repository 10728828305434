import React from 'react'
import { Row } from 'react-bootstrap'
import { Tweet } from 'react-twitter-widgets'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import './style.css'

function TweetReco({tweetId, Id}){
    return(
        <Row>
            <div id={Id} className="tweet-section">
               <LazyLoadComponent>
                   <Tweet tweetId={ tweetId } 
                        options={{
                            height: '400',
                            conversation: 'none',
                            cards:'hidden'
                        }} 
                    /> 
                </LazyLoadComponent>
            </div>
        </Row> 
    )
}

export default TweetReco;
