import React from 'react'
import { Link } from 'gatsby'
import { Row, Col, Image } from 'react-bootstrap'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { getInfluencerDetails } from '../../services/influencerdetails'
import { formatDate } from '../../services/format'

import {FaTwitter} from 'react-icons/fa'

import './style.css'

function TweetCard({reco}){
    const { name, hyphen_name, screen_name, profile_image } = getInfluencerDetails(reco.influencer)
    const tweet_date = formatDate(reco.created_at)

    return (
            <Row className="tweet-card">
                <Row className="left-space">
                    <Col xs={3} sm={2} md={2} lg={2} style={{paddingRight:0}}>
                        <Link to={"/books/" + hyphen_name}>
                            <LazyLoadComponent>
                                <Image src={profile_image} className="photo-thumbnail" alt={name} roundedCircle />
                            </LazyLoadComponent>
                        </Link>
                    </Col>
                    <Col xs={7} sm={8} className="influencer" style={{paddingLeft:0}}>
                        <div className="name"> 
                            <Link to={"/books/" + hyphen_name}>{name}</Link>
                        </div>
                        <div>
                            <a href={"https://twitter.com/" + screen_name } target="_blank" rel="noreferrer">@{screen_name}</a>
                        </div>   
                    </Col>
                    <Col className="recotweet" style={{textAlign:"right"}}>
                        <a href={"https://twitter.com/" + screen_name + "/status/" + reco.tweet_id} target="_blank" rel="noreferrer"><FaTwitter/></a>
                    </Col>
                </Row>
                <Row className="text">
                    <p style={{margin:"10px 0px 0px 5px"}}>{reco.text}</p>
                </Row>
                <Row className="tweet-card-footer">
                    <p className="left-space">{tweet_date}</p>
                </Row>
            </Row> 
    )
}

export default TweetCard;